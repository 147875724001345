import React from "react";
import PropTypes from "prop-types";
import { Image, Link, Title, Excerpt, CreatorDate } from "./styles";

const Preview = ({ author, url, title, content, createdAt, imgSrc }) => {
  let creatorDate = createdAt;
  creatorDate = author ? `${author}, ${creatorDate}` : creatorDate;

  return (
    <Link to={url}>
      <Image src={imgSrc} />
      <Title variant="body">{title}</Title>
      <Excerpt variant="body">{content}</Excerpt>
      <CreatorDate variant="subtitle">{creatorDate}</CreatorDate>
    </Link>
  );
};

Preview.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default Preview;

import styled from "@emotion/styled";
import Typography from "../../components/Typography";
import colors from "../../styles/colors";
import { MAX_WIDTH } from "./constants";

export const InsideOut = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
`;

export const InsideOutSubtitle = styled(Typography)`
  color: ${colors.grey};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const Title = styled(Typography)`
  margin-top: 0rem;
  margin-bottom: 8rem;
  max-width: ${MAX_WIDTH};
`;

export const PreviewContainer = styled.div`
  margin-bottom: ${(props) => (props.last ? "0rem" : "4.5rem")};
`;

import styled from "@emotion/styled";
import { Link as LinkBase } from "gatsby";
import borderRadii from "../../../styles/borderRadii";
import shadows from "../../../styles/shadows";
import Typography from "../../../components/Typography";
import { MAX_WIDTH } from "../constants";

export const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const Excerpt = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1rem;
  max-width: ${MAX_WIDTH};
`;

export const Image = styled.img`
  border-radius: ${borderRadii.large};
  margin-bottom: 1.5rem;
`;

export const Link = styled(LinkBase)`
  &:hover {
    cursor: pointer;

    ${Image} {
      box-shadow: ${shadows.large};
      transition: 0.3s all;
    }
  }
`;

export const CreatorDate = styled(Typography)`
  font-weight: normal;
`;

/*
 * Individual blog post pages are dynamically generated in gatsby-node.js
 */
import React from "react";
import { graphql } from "gatsby";
import BlogScreen from "../../screens/Blog";

const Blog = (props) => <BlogScreen {...props} />;

export const pageQuery = graphql`
  query {
    allPrismicPost {
      edges {
        node {
          data {
            content {
              text
            }
            title {
              text
            }
            created_at(formatString: "MMMM Do, YYYY")
            header {
              url
            }
            author {
              text
            }
          }
          uid
        }
      }
    }
  }
`;

export default Blog;

import React from "react";
import Section from "../../components/Section";
import SEO from "../../components/SEO";
import MaxWidth from "../../components/MaxWidth";
import Preview from "./Preview";
import {
  PreviewContainer,
  InsideOut,
  InsideOutSubtitle,
  Title,
} from "./styles";

const Blog = (props) => {
  const {
    data: {
      allPrismicPost: { edges },
    },
  } = props;

  const renderPreview = (node, last) => {
    const {
      data: {
        title: { text: title },
        header: { url: imgSrc },
        content: { text: content },
        created_at: createdAt,
        author: { text: author },
      },
      uid,
    } = node;

    return (
      <PreviewContainer last={last}>
        <Preview
          url={`/blog/${uid}`}
          title={title}
          author={author}
          content={content}
          imgSrc={imgSrc}
          createdAt={createdAt}
        />
      </PreviewContainer>
    );
  };

  return (
    <div>
      <SEO />
      <Section shrinkTop>
        <MaxWidth>
          <InsideOut>
            <InsideOutSubtitle variant="body">Inside Outline</InsideOutSubtitle>
          </InsideOut>
          <Title variant="h1">
            Stay up to date on customer practices, design, and other company
            news
          </Title>
          <div>
            {edges.map(({ node }, index) =>
              renderPreview(node, index === edges.length - 1),
            )}
          </div>
        </MaxWidth>
      </Section>
    </div>
  );
};

export default Blog;
